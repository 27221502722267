.pizz-header {
  z-index: 12;
  position: relative;
  margin: 0 auto;

  width: 100%;

  .header-logo {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 261px;
    margin-left: auto;
    margin-right: auto;
    @include respond-below(mmlg) {
      width: 196px;
    }
    @include respond-below(md) {
      left: 52px;
      margin-left: unset;
      margin-right: unset;
    }
    @include respond-below(xs) {
      left: 36px;
    }

    .desc-logo {
      @include respond-below(sm) {
        display: none;
      }
    }

    .mob-logo {
      display: none;
      @include respond-below(sm) {
        display: block;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .header-menu {
    height: 83px;
    display: flex;
    justify-content: flex-end;
    padding: 0 81px;
    position: relative;
    @include respond-below(mmlg) {
      height: 67px;
      padding: 0 52px;
    }
    @include respond-below(sm) {
      height: 56px;
    }
    @include respond-below(xs) {
      padding: 0 37px;
    }

    .menu-btn {
      margin-top: 29px;
      font-family: 'Bw Vivant Med';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      text-transform: uppercase;
      color: #E22625;
      height: max-content;
      @include respond-below(mmlg) {
        font-size: 16px;
        line-height: 19px;
        margin-top: 24px;
      }
      @include respond-below(sm) {
        margin-top: 19px;
      }
    }

    .menu-items {
      transition: 0.5s;
      height: 501px;
      //height: 536px;
      //@include respond-below(mlg) {
      //  height: 421px;
      //}
      @include respond-below(xs) {
        height: 454px;
      }
      transform: translateY(-120%);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0;
      top: 0;
      width: 320px;
      //height: 536px;
      background-image: url("../assets/img/nav-menu-bg2.svg");
      background-position: bottom;
      @include respond-below(xs) {
        width: 89%;
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: auto;
        margin-left: auto;
        left: 0;
      }

      .menu-cont {
        padding: 29px 27px 78px 76px;
        height: 100%;
        display: flex;
        flex-direction: column;
        transition: 0.5s;
        @include respond-below(mlg) {
          padding: 29px 27px 92px 76px;
        }
        @include respond-below(xs) {
          padding: 29px 27px 129px 76px;
        }
      }

      .nav-menu-header {
        display: flex;
        justify-content: flex-end;
        @include respond-below(lg) {
          justify-content: space-between;
          height: 71px;
          margin-left: -27px;
        }
        @include respond-below(xs) {
          max-height: 100px;
          height: 100%;
          flex: 1 0 auto;
        }

        .menu-header-title {
          font-family: 'Bw Vivant Med';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          text-transform: uppercase;
          color: #FCFAF6;
          margin-right: 35px;
          @include respond-below(lg) {
            font-size: 36px;
            line-height: 43px;
            height: min-content;
            align-self: flex-end;
          }
          @include respond-below(xs) {
            font-size: 48px;
            line-height: 58px
          }
        }

        .menu-header-close {

        }
      }

      .nav-menu-body {
        opacity: 0;
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        //justify-content: flex-end;
        height: 100%;
        //margin-top: 115px;

        .nav-menu-item {
          margin-top: 42px;
          font-family: 'OpiumNewC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 108.5%;
          color: #FCFAF5;
          text-decoration: none;
          @include respond-below(xs) {
            font-size: 14px;
            margin-top: 27px;
          }
        }

        .buy-selects {
          overflow: hidden;
          transition: 0.2s;
          height: 0;
          padding-left: 37px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .nav-menu-item {
            margin-top: 23px;
            @include respond-below(xs) {
              margin-top: 21px;
            }
          }
        }

        .select-menu-item {
          display: flex;
          align-items: center;
        }

        .select-menu-item:after {
          transition: 0.3s;
          transform: rotate(180deg);
          content: "";
          display: block;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 0L11.1962 9H0.803848L6 0Z' fill='white'/%3E%3C/svg%3E%0A");
          width: 12px;
          height: 9px;
          margin-left: 8px;
        }
      }
    }

    .open {
      transform: translateY(0);
      //height: 536px;
      //@include respond-below(mlg) {
      //  height: 421px;
      //}
      //@include respond-below(xs) {
      //  height: 536px;
      //}


      .nav-menu-body {
        opacity: 1;
      }
    }

    .open-byu-select {
      height: 656.04px;
      @include respond-below(xs) {
        height: 609px;
      }
      .buy-selects {
        height: 100% !important;
        transition: 1.3s !important;
      }
      .select-menu-item:after {
        transform: rotate(0deg) !important;
      }
    }
  }
}