.pizz-foo {
  width: 100%;
  height: 236px;
  background-color: #E22625;
  @include respond-below(lg) {
    height: 160px;
  }
  @include respond-below(xs) {
    height: auto;
    padding: 20px 11px;
  }

  .footer-menu {
    margin: 0 auto;
    max-width: 1660px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @include respond-below(xs) {
      flex-direction: column;
      order: 1;
    }

    .footer-logo-desc {
      flex: 1 0 auto;
      @include respond-below(lg) {
        width: 149px;
      }
      @include respond-below(sm) {
        width: 120px;
      }
      @include respond-below(xs) {
        display: none;
      }
    }

    .footer-logo-mobile {
      display: none;
      @include respond-below(xs) {
        display: block;
        margin-bottom: 25px;
      }
    }

    .menu-items {
      width: 100%;
      display: flex;
      justify-content: space-around;
      @include respond-below(xs) {
        flex-direction: column;
      }
      @include respond-below(xs) {
        order: 1;
        align-items: center;
      }

      .footer-menu-item {
        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 108.5%;
        color: #FCFAF5;
        @include respond-below(lg) {
          font-size: 12px;
        }
        @include respond-below(xs) {
          font-size: 14px;
          margin-bottom: 26px;
        }
      }
    }
  }
}