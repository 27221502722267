.espresso-block {
  overflow: hidden;
  padding: 0 12.7%;
  margin-bottom: 105px;
  @include respond-below(elg) {
    padding: 0 8.7%;
    margin-bottom: 90px;
  }
  @include respond-below(lg) {
    padding: 0 3.8%;
    margin-bottom: 49px;
  }
  @include respond-below(sm) {
    margin-bottom: 37px;
  }

  .espresso-title {
    font-family: 'OpiumNewC';
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 143.5%;
    text-transform: uppercase;
    color: #A09488;
    margin-bottom: 60px;
    //margin-left: 12.7%;
    @include respond-below(elg) {
      font-size: 48px;
      margin-bottom: 36px;
    }
    @include respond-below(sm) {
      font-size: 32px;
      padding-right: 30px;
    }
    @include respond-below(xs) {
      margin-bottom: 60px;
    }
    .line-svg {
      display: none;
      @include respond-below(xs) {
        display: block;
      }
    }
  }

  .espresso-slider {
    width: 100%;
    //padding: 0 0 0 12.7%;
    @include respond-below(lg) {
      margin-left: 60px;
    }
    @include respond-below(sm) {
      margin-left: 20px;
    }

    .espresso-slide {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: flex-end;
      //padding: 0 125px;
      flex-shrink: 100 !important;
      width: 563px !important;
      @include respond-below(elg) {
        width: 395px !important;
      }
      @include respond-below(sm) {
        width: 235.73px !important;
      }

      .slide-img {
        align-self: flex-end;
        width: 541px;
        height: 484px;
        padding-left: 103px;
        @include respond-below(elg) {
          width: 395px;
          height: 323px;
        }
        @include respond-below(sm) {
          width: 235.73px;
          height: 260.49px;
          padding: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .slide-title {
        //margin-top: -100px;
        position: absolute;
        bottom: 36px;
        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 108.5%;
        color: #A09488;
        width: 541px;
        padding-right: 179px;
        @include respond-below(elg) {
          font-size: 32px;
          padding-right: 87px;
          width: 379px;
        }
        @include respond-below(sm) {
          margin-top: 24px;
          font-size: 24px;
          position: unset;
          padding: 0;
          width: unset;
        }
      }
    }

  }

}
.slider-nav-arrows {
  display: flex;
  justify-content: flex-end;
  margin-top: 73px;
  margin-right: 204px;
  @include respond-below(elg) {
    margin-right: 104px;
  }
  @include respond-below(lg) {
    margin-right: 50px;
    margin-top: 49px;
  }
  @include respond-below(xs) {
    margin-right: 20px;
  }
  .desc-svg {
    @include respond-below(xs) {
      display: none;
    }
  }
  .mob-svg {
    display: none;
    @include respond-below(xs) {
      display: block;
    }
  }
  svg {
    path {
      fill: #E22625;
    }
  }
  .slider-prev-arrow {
    margin-right: 45px;
    @include respond-below(sm) {
      margin-right: 24px;
    }
  }
  .swiper-button-disabled {
    svg {
      path {
        fill: #A09488;
      }
    }
  }
}