.first-screen {
  position: relative;
  padding: 0 66px;
  margin-bottom: 107px;
  //@include respond-below(exlg) {
  //  min-height: unset;
  //}
  @include respond-below(mlg) {
    padding: 0 30px;
    margin-bottom: 80px;
  }
  @include respond-below(md) {
    padding: 0 30px;
    height: 479px;
  }
  @include respond-below(xs) {
    padding: 0 20px;
    margin-bottom: 30px;
    min-height: 479px;
    height: unset;
  }
  @include respond-below(exs) {
    height: 479px;
  }

  .first-screen-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .first-screen-bg.hide-576 {
    @include respond-below(xs) {
      display: none;
    }
  }

  .first-screen-bg.show-576 {
    display: none;
    @include respond-below(xs) {
      display: block;
    }
  }

  .first-screen-text {
    position: absolute;
    top: 346px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0 125px;
    @include respond-below(exlg) {
      top: 240px;
    }
    @include respond-below(mlg) {
      top: 200px;
      padding: 0 30px;
    }
    @include respond-below(xs) {
      padding: 0 20px;
    }
    @include respond-below(mxs) {
      top: 160px;
    }

    .first-screen-title {
      font-family: 'bw-vivant';
      font-style: normal;
      font-weight: 400;
      font-size: 94px;
      line-height: 113px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #FCFAF5;
      text-align: center;
      width: 100%;
      @include respond-below(mlg) {
        font-size: 85px;
        line-height: 96px;
      }
      @include respond-below(lg) {
        font-size: 64px;
        line-height: 76px;
      }
      @include respond-below(md) {
        font-size: 45px;
        line-height: 55px;
      }
      @include respond-below(sm) {
        font-size: 36px;
        line-height: 43px;
      }
      @include respond-below(xs) {
        text-align: left;
      }
      @include respond-below(mxs) {
        font-size: 30px;
        line-height: 37px;
      }
    }

    .first-screen-subtitle {
      font-family: 'OpiumNewC';
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      line-height: 46px;
      letter-spacing: 0.445em;
      color: #FCFAF5;
      text-align: center;
      @include respond-below(mlg) {
        font-size: 16px;
        line-height: 45px;
      }
      @include respond-below(xs) {
        text-align: left;
        padding-left: 18px;
        line-height: 24px;
      }
      @include respond-below(mxs) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .title-show-anim-cont {
      display: block;
      position: relative;
      overflow: hidden;
      @include respond-below(xs) {
        padding-left: 18px;
      }
    }
  }
}

.line-down-top-show {
  transform: translate(0px, 212px);
  display: inline-block;
  transition: 1.7s;
}