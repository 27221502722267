// stylelint-disable
*{outline:0;box-sizing:border-box;-moz-box-sizing:border-box;//mozilla firefox 28 bug
    -webkit-tap-highlight-color: rgba(0,0,0,0);//Remove Gray Highlight When Tapping Links in Mobile Safari and Webkit
    -webkit-tap-highlight-color: transparent; // For some Androids
    tap-highlight-color: rgba(0,0,0,0);}
  :before,:after{outline:0;box-sizing:border-box;-moz-box-sizing:border-box;}
  html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;vertical-align:baseline;background:transparent;}
  main,article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}
  ul{list-style:none;}
  blockquote,q{quotes:none;}
  blockquote:before,blockquote:after,q:before,q:after{content:none;}
  a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;}
  ins{background-color:#ff9;color:#000;text-decoration:none;}
  mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold;}
  del{text-decoration:line-through;}
  abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help;}
  table{border-collapse:collapse;border-spacing:0;width:100%;}
  hr{display:block;height:1px;border:0;border-top:1px solid #eee;margin:0;padding:0;}
  input,select{vertical-align:middle;}
  