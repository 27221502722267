.open-facts-block {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100vh;
  transition: 1s;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translate(-100%, 0);
  //padding-bottom: 236px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @include respond-below(lg) {
    //transform: translate(0, -600px);
    //padding-bottom: 160px;
  }
  @include respond-below(xs) {
    //transform: translate(0, -900px);
  }
  //max-height: calc(100% - 236px);
  .pizz-header {
    position: absolute;
    @include respond-below(xs) {
      position: relative;
      background: #FCFAF6;
    }

    .header-menu {
      .menu-btn {
        @include respond-below(xs) {
          display: none;
        }
      }

      .modal-close2 {
        margin-right: 72px;
        @include respond-below(mdw) {
          margin-right: 0;
        }
        @include respond-below(xs) {
          display: none;
        }
      }
    }
  }

  .pizz-foo {
    position: absolute;
  }

  .facts-body {
    width: 100%;
    height: 100%;
    min-height: 1080px;
    @include respond-below(elg) {
      min-height: 900px;
    }
    @include respond-below(lg) {
      min-height: 600px;
    }
    @include respond-below(xs) {
      min-height: 960px;
    }

    .facts-slider {
      position: relative;
      width: 100%;
      height: 100%;

      .fact-slide {
        position: relative;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 311px 16.25% 153px 12.3%;
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        @include respond-below(lg) {
          padding: 196px 15.1% 153px;
        }
        @include respond-below(md) {
          padding: 196px 52px 153px;
        }
        @include respond-below(xs) {
          background-image: unset !important;
          background: #FCFAF6;
          padding: 0;
          justify-content: flex-start;
        }

        .fact-title {
          font-family: 'OpiumNewC';
          font-style: normal;
          font-weight: 400;
          font-size: 80px;
          line-height: 108.5%;
          color: #FBF9F4;
          max-width: 760px;
          overflow: hidden;
          margin-bottom: 20px;
          min-height: 172px;
          @include respond-below(elg) {
            font-size: 60px;
          }
          @include respond-below(lg) {
            font-size: 40px;
            max-width: 358px;
            min-height: 86px;
          }
          @include respond-above(xs) {
            background-image: unset !important;
          }
          @include respond-below(xs) {
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            //max-height: 337px;
            height: 337px;
            max-width: 100%;
            //background-image: unset
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0 41px 49px;
            font-size: 28px;
          }

          p {
            transform: translate(0px, 100%);
            transition-delay: 1.3s !important;
            transition: 0.5s;
            @include respond-below(sm) {
              transform: translate(0px, 0px);
            }
          }
        }

        .fact-desc {
          align-self: flex-end;
          font-family: 'OpiumNewC';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 137%;
          color: #FBF9F4;
          max-width: 737px;
          overflow: hidden;

          @include respond-below(elg) {
            font-size: 18px;
          }
          @include respond-below(lg) {
            font-size: 14px;
            max-width: 556px;
          }
          @include respond-below(md) {
            align-self: flex-start;
          }
          @include respond-below(xs) {
            width: 100%;
            max-width: 100%;
            padding: 37px 41px 24px;
            color: #A09488;
            max-height: unset;
            line-height: 206.5%;
          }
          @include respond-below(mxs) {
            padding: 37px 25px 24px;
          }

          p {
            transform: translate(0px, 100%);
            transition-delay: 1.3s !important;
            transition: 0.5s;
            @include respond-below(sm) {
              transform: translate(0px, 0px);
            }
          }
        }
      }

      .fact-slide:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .6);
        z-index: -1;
        @include respond-below(xs) {
          content: unset;
        }
      }

      .slider-arrow {
        z-index: 1;
        position: absolute;
        bottom: 264px;
        cursor: pointer;
        @include respond-below(elg) {
          bottom: 160px;
        }
        @include respond-below(lg) {
          bottom: 63px;
        }
        @include respond-below(xs) {
          bottom: 66px;
        }

        .arrow-desc {
          font-family: 'OpiumNewC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 137%;
          color: #FBF9F4;
          @include respond-below(xs) {
            color: #A09488;
            max-width: 81px;
          }
        }
      }

      .slider-prev-arrow {
        left: 8.28%;
        @include respond-below(elg) {
          left: 6.28%;
        }
        @include respond-below(lg) {
          left: 11.4%;
        }
        @include respond-below(md) {
          left: 52px;
        }
        @include respond-below(xs) {
          left: 26.3%;
        }
        @include respond-below(mxs) {
          left: 60px;
        }

        svg {
          path {
            @include respond-below(xs) {
              fill: #A09488;
            }
          }
        }
      }

      .slider-next-arrow {
        right: 8.28%;
        bottom: 245px;
        @include respond-below(elg) {
          right: 6.28%;
          bottom: 145px;
        }
        @include respond-below(lg) {
          right: 8.1%;
          bottom: 44px;
        }
        @include respond-below(md) {
          right: 52px;
        }
        @include respond-below(md) {
          bottom: 28px;
        }
        @include respond-below(xs) {
          right: 26.3%;
        }
        @include respond-below(mxs) {
          right: 60px;
        }

        svg {
          path {
            @include respond-below(xs) {
              fill: #A09488;
            }
          }
        }
      }
    }
  }

  .fact-container {
    width: 100vw;
    height: 100%;
  }

}

.open-facts-block::-webkit-scrollbar {
  display: none;
}

.facts-header-btn {
  transition: 1s;
  opacity: 0;
  z-index: 13;
  position: absolute;
  top: 32px;
  left: 6.7%;
  @include respond-below(md) {
    left: 10px;
    top: 11px;
  }
  @include respond-below(xs) {
    right: 22px;
    top: 25px;
    left: unset;
  }

  .return-btn {
    display: flex;
    align-items: center;

    .desc-svg {
      path {
        fill: #fff;
        @include respond-below(xs) {
          fill: #E22625;
        }
      }

      @include respond-below(md) {
        display: none;
      }
      @include respond-below(xs) {
        display: block;
      }
    }

    .mob-svg {
      display: none;
      @include respond-below(md) {
        display: block;
      }
      width: 30px;
      height: 30px;
      @include respond-below(xs) {
        width: unset;
        height: unset;
        display: none;
      }

      path {
        fill: #fff;
        @include respond-below(xs) {
          fill: #E22625;
        }
      }
    }

    .return-text {
      font-family: 'OpiumNewC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 137%;
      //color: #E22625;
      color: #fff;
      margin-left: 22px;
      @include respond-below(md) {
        display: none;
      }
    }
  }
}

.facts-show {
  transform: translate(0, 0);
  width: 100%;

  .fact-title {
    p {
      transform: translate(0, 0) !important;
    }
  }

  .fact-desc {
    p {
      transform: translate(0, 0) !important;
    }
  }
}

.open-fact {
  //height: 817px !important;
  overflow: hidden !important;
  @include respond-below(lg) {
    //height: 533px !important;
  }
  @include respond-below(xs) {
    //height: 900px !important;
  }
}