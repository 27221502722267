.professional-block {
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  .column-title {
    font-family: 'OpiumNewC';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 143.5%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 21px;
    @include respond-below(mlg) {
      font-size: 25px;
    }
  }

  .column-desc {
    font-family: 'OpiumNewC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 163%;
    color: #A09488;
    @include respond-below(mlg) {
      font-size: 14px;
    }
  }

  .professional-title {
    position: relative;
    flex-direction: column;
    margin-bottom: 140px;
    display: block !important;
    @include respond-below(mlg) {
      margin-bottom: 78px;
    }
    @include respond-below(mdw) {
      overflow: hidden;
    }

    span {
      display: block;
    }
    .first-line {
      //transition: 0.1s;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.12, 0.95);
      white-space: nowrap;
      padding-left: 15.26%;
      font-family: 'OpiumNewC';
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 143.5%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #A09488;
      @include respond-below(elg) {
        font-size: 48px;
      }
      @include respond-below(sm) {
        font-size: 32px;
        padding-left: 5.2%;
      }
      span {
        transform: translateX(-100%);
        transition: 2s;
        @include respond-below(lxs) {
          transform: translateX(100%);
          transition: 0s;
        }
      }
    }

    .second-line {
      overflow: hidden;
      display: flex;
      align-items: center;
      padding-left: 5.2%;
      width: 100%;
      @include respond-below(mdw) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }
      @include respond-below(sm) {
        padding-left: 15.26%;
      }

      .line-text {
        transition: 0.1s;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.12, 0.95);
        white-space: nowrap;
        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 80px;
        line-height: 143.5%;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #DAD1C8;
        @include respond-below(elg) {
          font-size: 48px;
        }
        @include respond-below(sm) {
          font-size: 32px;
        }
        span {
          transform: translateX(100%);
          transition: 2.2s;
          @include respond-below(lxs) {
            transition: 0s;
          }
        }
      }
    }
  }

  .professional-banner {
    position: relative;
    width: 100%;
    height: 461px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    @include respond-below(mlg) {
      height: 270px;
    }
    @include respond-below(sm) {
      height: auto;
      margin-left: 23px;
      margin-top: 22px;
      margin-bottom: 45px;
    }

    .banner {
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
      @include respond-below(sm) {
        position: relative;
        margin-bottom: 36px;
        height: 182px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include respond-below(sm) {
          object-position: -92px;
        }
      }
    }

    .banner-text-cont {
      width: 740px;
      margin-right: 5.2%;
      @include respond-below(mlg) {
        width: 482px;
      }
      @include respond-below(sm) {
        width: 100%;
        margin: 0;
      }

      .banner-num {
        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 126px;
        line-height: 143.5%;
        text-transform: uppercase;
        color: #736250;
        @include respond-below(mlg) {
          font-size: 80px;
        }
        @include respond-below(xs) {
          font-size: 48px;
        }
      }

      .banner-text {
        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 143.5%;
        color: #000000;
        @include respond-below(mlg) {
          font-size: 16px;
          max-width: 325px;
          margin: 0 auto;
        }
        @include respond-below(xs) {
          font-size: 14px;
          max-width: 193px;
        }
      }
    }
  }

  > div {
    display: flex;
  }

  .professional-first-line {
    position: relative;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10% 48px;
    //margin-bottom: 48px;
    @include respond-below(mlg) {
      padding: 0 5% 48px;
    }
    @include respond-below(sm) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 5% 32px 0;
    }

    .marquee-container {
      top: 293px;
      width: 100%;
      @include respond-below(mlg) {
        top: 160px;
      }
      @include respond-below(sm) {
        top: 0;
      }
      @include respond-below(sm) {
        left: 120px;
        overflow: visible;
      }
      z-index: 1;

      .marquee {
        transform: translateX(-100%);
      }
    }

    .left-column {
      max-width: 580px;
      width: 100%;
      max-height: 383px;
      height: 383px;
      @include respond-below(lg) {
        max-width: 370.48px;
        max-height: 233px;
      }
      @include respond-below(sm) {
        max-width: 51%;
        margin-bottom: 30px;
      }
      @include respond-below(xs) {
        max-width: 78%;
        margin-bottom: 30px;
      }

      .img-container {
        position: relative;
        height: 100%;

        img {
          position: absolute;
          left: 0px;
          bottom: 0px;
        }
      }

      img {
        max-width: 608px;
        width: 100%;
        max-height: 315px;
        height: 100%;
        object-fit: cover;
        @include respond-below(lg) {
          max-width: 370.48px;
          max-height: 233px;
        }
        @include respond-below(sm) {
          max-width: unset;
          max-height: unset;
          //filter: opacity(0.7);
        }
      }
    }

    .right-column {
      z-index: 1;
      max-width: 584px;
      margin-left: 84px;
      @include respond-below(lg) {
        margin-top: 106px;
        margin-left: 44px;
        max-width: unset;
      }
      @include respond-below(mdw) {
        margin-top: 0;
      }
      @include respond-below(sm) {
        margin-top: -125px;
      }
      @include respond-below(xs) {
        margin-left: 0;
      }

      .column-title {
        @include respond-below(sm) {
          text-align: end;
        }
        @include respond-below(sm) {
          margin-bottom: 81px;
        }
      }

      .column-desc {
        padding-left: 80px;
        @include respond-below(lg) {
          padding-left: 30px;
          max-width: 438px;
        }
        @include respond-below(sm) {
          max-width: unset;
        }
      }
    }
  }

  .professional-second-line {
    position: relative;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 21% 136px 10%;

    @include respond-below(mlg) {
      padding: 0 16% 136px 11.7%;
    }
    @include respond-below(lg) {
      padding: 0 11.7% 61px 11.7%;
      justify-content: flex-start;
      align-items: center;
    }
    @include respond-below(sm) {
      flex-direction: column-reverse;
    }

    .marquee-container {
      width: 50%;
      z-index: 1;
      top: 193px;
      right: 0;
      left: unset;
      @include respond-below(mlg) {
        top: 160px;
      }
      @include respond-below(sm) {
        top: 130px;
      }
      @include respond-below(xs) {
        right: 185px;
      }

      .marquee {
        font-size: 335px;
        @include respond-below(sm) {
          font-size: 96px;
        }
      }
    }

    .left-column {
      z-index: 3;
      max-width: 515px;
      margin-right: 197px;
      @include respond-below(mlg) {
        margin-right: 74px;
      }
      @include respond-below(lg) {
        max-width: unset;
      }
      @include respond-below(sm) {
        margin-top: -130px;
        margin-right: 0;
      }

      .column-title {
        @include respond-below(sm) {
          margin-bottom: 94px;
          margin-left: 40px;
        }
      }

      .column-desc {
        padding-left: 62px;
        @include respond-below(lg) {
          padding-left: 0;
          max-width: 425px;
        }
      }
    }

    .right-column {
      max-width: 322px;
      width: 100%;
      max-height: 623px;
      height: 623px;
      @include respond-below(sm) {
        margin-bottom: 30px;
        align-self: flex-end;
        height: 323px;
      }
      @include respond-below(xs) {
        max-width: 172.63px;
        z-index: 2;
      }

      .img-container {
        position: relative;
        height: 100%;

        img {
          position: absolute;
          left: 0px;
          bottom: 0px;
        }
      }

      img {
        object-fit: cover;
        max-width: 322px;
        width: 100%;
        max-height: 623px;
        height: 100%;
        @include respond-below(lg) {
          max-width: 239.3px;
          max-height: 463px;
          object-fit: cover;
        }
        @include respond-below(sm) {
          width: 172.63px;
          height: 334px;
          object-fit: cover;
          //filter: opacity(0.7);
        }
      }
    }
  }

  .professional-third-line {
    position: relative;
    //align-items: flex-end;
    justify-content: flex-start;
    padding: 0 10% 0 16.6%;
    margin-top: -20px;
    @include respond-below(elg) {
      align-items: flex-end;
    }
    @include respond-below(mlg) {
      padding: 0 10% 0 10%;
    }
    @include respond-below(mlg) {
    }
    @include respond-below(sm) {
      flex-direction: column;
      padding: 0 10%;
    }
    @include respond-below(xs) {
      padding: 0 5.5% 58px 5.5%;
    }

    .marquee-container {
      top: 160px;
      right: 0;
      z-index: 1;
      left: unset;
      width: 100%;
      @include respond-below(sm) {
        top: 90px;
      }

      .marquee {
        transform: translateX(45%);
        padding-left: 16%;
        @include respond-below(mlg) {
          padding-left: 10%;
        }
      }
    }

    .left-column {
      max-width: 585px;
      width: 100%;
      //max-height: 410px;
      height: 623px;
      @include respond-below(elg) {
        height: 410px;
        width: 384px;
      }
      @include respond-below(sm) {
        align-self: flex-start;
        margin-bottom: 30px;
        height: 236px;
      }

      .img-container {
        position: relative;
        height: 100%;

        img {
          z-index: -1;
          position: absolute;
          left: 0px;
          bottom: 0px;
        }
      }

      img {
        max-width: 585px;
        width: 100%;
        max-height: 623px;
        height: 100%;
        object-fit: cover;
        @include respond-below(lg) {
          max-width: 381px;
          max-height: 405.75px;
        }
        @include respond-below(xs) {
          max-width: 222px;
          max-height: 236.42px;
          //filter: opacity(0.7);
        }
      }
    }

    .right-column {
      z-index: 1;
      max-width: 629px;
      margin-left: 129px;
      margin-top: 500px;
      @include respond-below(elg) {
        max-width: 560px;
        margin-top: unset;
      }
      @include respond-below(lg) {
        max-width: unset;
        margin-left: 69px;
        margin-bottom: 45px;
      }
      @include respond-below(sm) {
        margin-bottom: 21px;
        margin-left: 0;
      }

      .column-title {
        @include respond-below(lg) {
          max-width: 230px;
        }
        @include respond-below(sm) {
          margin-bottom: 28px;
        }
      }

      .column-desc {
        padding-left: 62px;
        @include respond-below(lg) {
          display: none;
        }
        @include respond-below(sm) {
          display: block;
          padding-left: 0;
        }
      }
    }
  }

  .professional-third-line-sub {
    display: none;
    @include respond-below(lg) {
      display: block;
      margin: 28px 11.7% 61px 11.7%;
    }
    @include respond-below(sm) {
      display: none;
    }

    .column-desc {
      margin: 0 auto;
      max-width: 561px;
    }
  }

  .professional-sert {
    background-color: #FCFAF6;
    flex-direction: column;
    margin-left: 16.6%;
    margin-top: 33px;
    margin-bottom: 130px;
    @include respond-below(mdw) {
      display: none;
    }
    @include respond-below(sm) {
      display: block;
      margin: 0 10% 58px;
    }
    @include respond-below(xs) {
      margin: 0 5.5% 58px 5.5%;
    }

    .title-line {
      display: flex;
      align-items: center;
      margin-bottom: 21px;
      @include respond-below(xs) {
        margin-bottom: 5px;
      }

      .column-title {
        margin-right: 40px;
        @include respond-below(xs) {
          margin-right: 0;
        }
      }

      .title-logo {
        width: 213px;
        height: 171px;
        @include respond-below(sm) {
          width: 101px;
          height: 81px;
        }
        img {
          mix-blend-mode: darken;
        }
      }
    }

    .sert-desc {
      margin-left: 308px;
      max-width: 633px;
      @include respond-below(mlg) {
        margin-left: 0;
      }
      @include respond-below(sm) {
        margin-left: 29px;
      }
    }
  }

  .marquee-container {
    position: absolute;
    top: 0;

    .marquee {
      font-family: 'Bw Vivant ExtLt';
      font-style: normal;
      font-weight: 100;
      font-size: 233px;
      line-height: 100%;
      text-transform: uppercase;
      color: #DAD1C8;
      opacity: 0.3;
      @include respond-below(sm) {
        font-size: 96px;
      }
    }
  }
}

.line-svg {
  margin-right: 48px;
  position: relative;
  height: 1px;
  background-color: #A09488;
  max-width: 472px;
  width: 100%;
  @include respond-below(mlg) {
    margin-right: 28px;
  }
  @include respond-below(mdw) {
    margin-bottom: 2px;
    margin-top: 15px;
    max-width: 221px;
  }
}

.line-svg:before {
  position: absolute;
  top: -3px;
  left: -3px;
  background-size: 100%;
  background-repeat: no-repeat;
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0L6 3L3 6L0 3L3 0Z' fill='%23A09488'/%3E%3C/svg%3E%0A");
}

.line-svg:after {
  position: absolute;
  top: -3px;
  right: -3px;
  background-size: 100%;
  background-repeat: no-repeat;
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='3' cy='3' r='3' fill='%23A09488'/%3E%3C/svg%3E%0A");
}