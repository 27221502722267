html {
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
  //overflow: hidden auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.is-mobile-overflow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
  }
}

a {
  cursor: pointer;
}