@import "basic/mixins";
@import "basic/fonts";
@import "basic/interface";
@import "basic/normalize";
@import "~animate.css/animate";
@import "~locomotive-scroll/dist/locomotive-scroll.css";

@import "components/header";
@import "components/footer";
@import "components/first-screen";
@import "components/open-border";
@import "components/colection";
@import "components/professional";
@import "components/espres";
@import "components/facts";


.slider-arrow {
  cursor: pointer;
}

.animate__animated.animate__delay-05s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 0.5);
  animation-delay: calc(var(--animate-delay) * 0.5);
}

.animate__animated.animate__delay-15s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 1.5);
  animation-delay: calc(var(--animate-delay) * 1.5);
}

body {
  min-height: 100vh;
}
.c-scrollbar {
  height: 100vh;
}
html.has-scroll-smooth {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
}

html {
  background-color: #FCFAF6;
}