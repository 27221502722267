.open-borders {
  position: relative;
  overflow: hidden;

  .titles-block {
    max-width: 1920px;
    margin: 0 auto;
    margin-bottom: 119px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include respond-below(elg) {
      margin-bottom: 80px;
    }
    @include respond-below(mmlg) {
      margin-bottom: 60px;
    }
    @include respond-below(mdw) {
      margin-bottom: 40px;
    }
    @include respond-below(sm) {
      margin-bottom: 20px;
    }
    @include respond-below(xs) {
      overflow: unset;
    }

    .first-line {
      align-self: flex-start;
      padding-left: 12.2%;
      transform: translate(-100%, 0px);
      transition: 3s;
      @include respond-below(elg) {
        padding-left: 11%;
      }
      @include respond-below(mdw) {
        padding-left: 40px;
      }
      @include respond-below(sm) {
        padding-left: 20px;
        padding-right: 85px;
      }
      @include respond-below(xs) {
        padding-left: 19px;
        transform: translate(100%, 0px);
      }
    }

    .second-line {
      align-self: flex-end;
      padding-right: 12.2%;
      transform: translate(100%, 0px);
      transition: 3s;
      @include respond-below(mdw) {
        padding-right: 40px;
      }
      @include respond-below(sm) {
        padding-right: 20px;
        padding-left: 85px;
      }
      @include respond-below(xs) {
        padding-right: 11px;
        transform: translate(100%, 0px);
      }
    }

    .open-borders-title {
      font-family: 'OpiumNewC';
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 45px;
      letter-spacing: 0.03em;
      color: #736250;
      width: max-content;
      @include respond-below(elg) {
        font-size: 25px;
        line-height: 35px;
      }
      @include respond-below(mmlg) {
        font-size: 16px;
        line-height: 35px;
      }
      @include respond-below(sm) {
        width: 100%;
        font-size: 15px;
        line-height: 30px;
      }

      span {
        text-transform: uppercase;
        color: #E22625;
      }
    }
  }

  .open-borders-line {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    height: 317px;

    @include respond-below(mmlg) {
      height: 206px;
    }
    @include respond-below(sm) {
      flex-direction: column;
      height: auto;
    }

    .line-cell {
      overflow: hidden;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 105px;
      @include respond-below(mmlg) {
        padding: 0 49px;
      }
      @include respond-below(sm) {
        padding: 0 42px;
      }

      .cell-text {
        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #A09488;
        max-width: 561px;
        transition: 2s;
        @include respond-below(mmlg) {
          font-size: 14px;
          line-height: 25px;
          max-width: 361px;
        }

        span {
          text-transform: uppercase;
          color: #E22625;
          letter-spacing: 0.34em;
        }
      }
    }

    .empty-cell {
      @include respond-below(sm) {
        display: none;
      }
    }

    .line-cell.right-cell {
      @include respond-below(xs) {
        padding: 0 54px 0 20px;
      }

      .cell-text {
        transform: translate(100%, 0px);
        @include respond-below(xs) {
          max-width: unset;
        }
      }
    }

    .line-cell.left-cell {
      justify-content: flex-end;
      @include respond-below(xs) {
        padding: 0 20px 0 85px;
      }

      .cell-text {
        transform: translate(-100%, 0px);
        @include respond-below(xs) {
          max-width: unset;
        }
      }
    }

    .line-cell.left-cell::after {
      background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* 2 */
      left: 0;
      @include respond-below(mmlg) {
        background: unset;
      }
    }

    .line-cell::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 105px; /* 1 */
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* 2 */
      @include respond-below(mmlg) {
        background: unset;
      }
    }

    .line-delimiter {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include respond-below(sm) {
        height: 119px;
        margin-bottom: 30px;
      }

      .top-rhombus {
        width: 0;
        height: 0;
        border: 2px solid transparent;
        border-bottom-color: #A09488;
        position: relative;
        top: -1px;
      }

      .top-rhombus:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        left: -2px;
        top: 2px;
        border: 2px solid transparent;
        border-top-color: #A09488;
      }

      .line-delimiter-bord {
        background-color: #A09488;
        width: 0;
        border: 1px solid #A09488;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 10px 0;
        position: relative;
        height: 0%;
        transition: 3s;
      }

      .line-delimiter-bord:before {
        position: absolute;
        top: -5px;
        left: -3px;
        background-size: 100%;
        background-repeat: no-repeat;
        content: "";
        width: 6px;
        height: 6px;
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0L6 3L3 6L0 3L3 0Z' fill='%23A09488'/%3E%3C/svg%3E%0A");
      }

      .line-delimiter-bord:after {
        position: absolute;
        bottom: -5px;
        left: -3px;
        background-size: 100%;
        background-repeat: no-repeat;
        content: "";
        width: 6px;
        height: 6px;
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='3' cy='3' r='3' fill='%23A09488'/%3E%3C/svg%3E%0A");
      }

      .bot-circle {
        width: 2px;
        border-radius: 50%;
      }
    }
  }

  .open-borders-line.last-line {
    .line-delimiter {
      @include respond-below(sm) {
        display: none;
      }

      .line-delimiter-bord:after {
        width: 8px;
        height: 8px;
        left: -4px;
        bottom: -5px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='14' viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.0607 13.0607C12.4749 13.6464 11.5251 13.6464 10.9393 13.0607L1.3934 3.51472C0.807613 2.92893 0.807613 1.97919 1.3934 1.3934C1.97919 0.807613 2.92893 0.807613 3.51472 1.3934L12 9.87868L20.4853 1.3934C21.0711 0.807613 22.0208 0.807613 22.6066 1.3934C23.1924 1.97919 23.1924 2.92893 22.6066 3.51472L13.0607 13.0607ZM13.5 11V12H10.5V11H13.5Z' fill='%23A09488'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .open-borders-subtitle {
    margin-bottom: 38px;
    @include respond-below(sm) {
      overflow: hidden;
    }

    .sub-title {
      text-align: center;
      font-family: 'OpiumNewC';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #A09488;
      transform: translateX(70%);
      transition: 2s;
      @include respond-below(sm) {
        white-space: nowrap;
        padding-left: 20px;
        transition: unset;
        transform: unset;
      }

      span {
        text-transform: uppercase;
        color: #E22625;
        letter-spacing: 0.34em;
      }

    }
  }
}