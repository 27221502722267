//@font-face {
//  font-family: 'OpiumNewC';
//  font-weight: 400;
//  font-style: normal;
//  src: url('../assets/font/opiin/Opium.woff2') format('woff2');
//  font-display: swap;
//}
@font-face {
  font-family: Opiumnewc;
  src: url('../assets/font/opiin/Opiumnewc.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpiumNewC';
  src: url('../assets/font/opiin/OpiumNewC.eot');
  src: url('../assets/font/opiin/OpiumNewC.eot?#iefix') format('embedded-opentype'),
  url('../assets/font/opiin/OpiumNewC.woff2') format('woff2'),
  url('../assets/font/opiin/OpiumNewC.woff') format('woff'),
  url('../assets/font/opiin/OpiumNewC.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Bw Vivant Med';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/font/Bw Vivant DEMO/BwVivant-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'bw-vivant';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/font/Bw Vivant DEMO/Unnamed-bwvivantregular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'bw-vivant';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/font/Bw Vivant DEMO/Unnamed-bwvivantregular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Bw Vivant DEMO';
  src: url('../assets/font/test-fonts/BwVivantDEMO-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bw Vivant ExtLt';
  src: url('../assets/font/Bw Vivant DEMO/BwVivant-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Optima Cyr';
  src: url('../assets/font/Optima/Optima.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}