.collection-block {
  margin-top: 193px;
  margin-bottom: 395px;
  display: flex;
  position: relative;
  @include respond-below(exlg) {
    margin-bottom: 300px;
  }
  @include respond-below(lg) {
    margin-top: 207px;
    margin-bottom: 200px;
  }
  @include respond-below(sm) {
    margin-top: 113px;
    margin-bottom: 110px;
  }
  @include respond-below(xs) {

    margin-bottom: 80px;
  }

  .marquee-container {
    z-index: -1;
    background: transparent;
    position: absolute;
    top: -200px;
    width: 100%;
    overflow: hidden;
    @include respond-below(elg) {
      top: -140px;
    }
    @include respond-below(sm) {
      top: -77px;
    }

    .marquee {
      font-family: 'Bw Vivant DEMO';
      font-style: normal;
      font-weight: 300;
      font-size: 185px;
      line-height: 280px;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: #DAD1C8;
      margin: 0 auto;
      width: max-content;

      //@include respond-below(exlg) {
      //  font-size: 185px;
      //}
      @include respond-below(elg) {
        font-size: 172px;
        line-height: 198px;
        top: -140px;
      }
      @include respond-below(mlg) {
        font-size: 179px;
      }
      @include respond-below(mmlg) {
        font-size: 112px;
      }
      @include respond-below(mdw) {
        font-size: 96px;
      }
      @include respond-below(sm) {
        line-height: 115px;
        top: -77px;
      }
    }
  }

  .collection-BG {
    position: absolute;
    z-index: -1;
    left: 0;
    top: -250px;
    @include respond-below(elg) {
      height: 717px;
      top: -200px;
    }
    @include respond-below(sm) {
      left: -65px;
      height: 489px;
    }
  }

  .collection-scroll {
    overflow-x: scroll;
    width: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @include respond-below(lg) {
      overflow-x: hidden;
    }
    @include respond-below(sm) {
      //overflow-x: scroll;
      padding: 0 5% 0 5%;
    }

    .slider-nav-arrows {
      margin-top: 30px !important;
      margin-right: 0 !important;
    }

    .collection-container {
      transition: 0.3s;
      max-width: 1252px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      box-sizing: border-box;
      flex: 1 0 auto;
      //padding: 0px 6% 0 775px;
      //padding: 0px 6%;
      margin: 0 auto;
      @include respond-below(mmlg) {
        padding: 0px 6% 0 6%;
      }
      @include respond-below(lg) {
        width: auto;
        max-width: unset;
        padding: 0px 6% 0 6%;
      }
      @include respond-below(sm) {
        flex: unset;
      }
      @include respond-below(xs) {
        padding: 0 5% 0 5%;
      }

      .collection-slide {
        opacity: 0;
        display: flex;
        flex-direction: column;
        //transition: 1s;
        --animate-duration: 0.5s;
        @include respond-below(sm) {
          opacity: 1;
          width: 138.97px;
          display: block;
          max-height: 244px;
        }
        img {
          width: 213px;
          height: 312px;
          @include respond-below(lg) {
            width: 165.7px;
            height: 242.72px;
          }
          @include respond-below(sm) {
            width: 138.97px;
            height: 203.57px;
          }
        }

        p {
          font-family: 'Bw Vivant DEMO';
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          letter-spacing: 0.055em;
          text-transform: capitalize;

          color: rgba(0, 0, 0, 0.7);
          @include respond-below(lg) {
            font-size: 20px;
            line-height: 24px;
          }
          @include respond-below(sm) {
            font-size: 15px;
            line-height: 18px;
          }
        }
      }

      .animate__fadeInRight {
        animation-name: none;
      }
    }

    .desc-collection {
      @include respond-below(sm) {
        display: none;
      }
    }
    .mob-collection {
      display: none;
      @include respond-below(sm) {
        display: flex;
        max-height: 245px;
        padding: 0;
      }
    }

    .show-collection-container {
      .animate__fadeInRight {
        animation-name: fadeInRight;
      }

      .animate__animated_1 {
        animation-delay: 0s;
        transition-delay: 0s;
      }

      .animate__animated_2 {
        //animation-delay: 0.5s !important;
        //transition-delay: 0.5s !important;
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
        -webkit-animation-delay: calc(var(--animate-delay) * 0.5);
        animation-delay: calc(var(--animate-delay) * 0.5);

      }

      .animate__animated_3 {
        animation-delay: 1.5s !important;
        transition-delay: 1.5s !important;
        -webkit-animation-delay: 1.5s;
        animation-delay: 1.5s;
        -webkit-animation-delay: calc(var(--animate-delay) * 1.5);
        animation-delay: calc(var(--animate-delay) * 1.5);
      }

      .animate__animated_4 {
        animation-delay: 2s !important;
        transition-delay: 2s !important;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-animation-delay: calc(var(--animate-delay) * 2);
        animation-delay: calc(var(--animate-delay) * 2);
      }
    }
  }

  .collection-scroll::-webkit-scrollbar {
    display: none;
  }

  .more-arrow {
    position: absolute;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    bottom: -174px;
    @include respond-below(lg) {
      bottom: -150px;
    }
    @include respond-below(sm) {
      display: none;
    }

    .more-text {
      font-family: 'OpiumNewC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 46px;
      letter-spacing: 0.1em;
      text-transform: lowercase;
      color: #A09488;
    }

    svg {
      @include respond-below(lg) {
        height: 42px;
      }
    }
  }
}

.collection-slider {
  z-index: 1;
  position: relative;
  margin-top: -50px;
  margin-bottom: 151px;
  @include respond-below(mlg) {
    padding-bottom: 101px;
    margin-bottom: 0;
  }
  @include respond-below(sm) {
    margin-top: 0;
    padding-bottom: 40px;
  }

  .desc-arr {
    @include respond-below(sm) {
      display: none;
    }
  }

  .mob-arr {
    display: none;
    @include respond-below(sm) {
      display: block;
    }
  }

  .slider-container {
    .slide {
      .collection-slide-title {
        position: relative;

        display: flex;
        height: 200px;
        margin-left: -19%;
        @include respond-below(exlg) {
          height: 180px;
        }
        @include respond-below(lg) {
          height: 110px;
        }
        @include respond-below(md) {
          margin-left: -7%;
        }
        @include respond-below(sm) {
          height: 120px;
          z-index: 2;
          margin-left: -21%;
        }
        @include respond-below(xs) {
          height: 160px;
        }

        .circle-text.circle {
          margin-left: 40px;
          position: relative; /*относительное позиционирование*/
          width: 120px; /*ширина круга*/
          height: 120px; /*высота круга*/
          border-radius: 50%; /*скругление углов*/
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent; /*цвет круга*/
          animation: animate 10s linear infinite;
          @include respond-below(mmlg) {
            margin-left: 20px;
          }
          @include respond-below(lg) {
            margin-left: 20px;
            width: 76px; /*ширина круга*/
            height: 76px; /*высота круга*/
          }
          @include respond-below(sm) {
            align-self: flex-end;
          }
          @include respond-below(xs) {
            margin-left: -36px;
          }

          .cyr-block {
            width: 100%;
            height: 100%;

            .cyr-text {
              width: 100%;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }


        .title-text {
          align-self: flex-end;
          font-family: 'bw-vivant';
          font-style: normal;
          font-weight: 400;
          font-size: 110px;
          line-height: 100%;
          letter-spacing: 0.025em;
          text-transform: uppercase;
          color: #C7BEB4;
          @include respond-below(exlg) {
            font-size: 80px;
          }
          @include respond-below(lg) {
            font-size: 64px;
          }
          @include respond-below(sm) {
            font-size: 40px;
            align-self: flex-start;
          }
          @include respond-below(xs) {
            max-width: 50%;
          }
        }
      }

      .slide-cont {
        display: flex;
        margin-top: -20px;
        overflow: hidden;
        @include respond-below(sm) {
          flex-direction: column;
          align-items: center;
          margin-top: 0;
        }

        .collection-img {
          //opacity: 0;
          overflow: hidden;
          width: 50%;
          display: flex;
          justify-content: flex-end;
          z-index: 1;
          @include respond-below(sm) {
            width: 100%;
            justify-content: center;
            margin-bottom: -155px;
          }

          img {
            opacity: 0;
            -webkit-transform: translatex(-4rem);
            transform: translatex(-4rem);
            -webkit-transition-duration: 1s;
            transition-duration: 1s;
            -webkit-transition-property: opacity, -webkit-transform;
            transition-property: opacity, -webkit-transform;
            transition-property: transform, opacity;
            transition-property: transform, opacity, -webkit-transform;
            -webkit-transition-timing-function: cubic-bezier(.645, .045, .355, 1);
            transition-timing-function: cubic-bezier(.645, .045, .355, 1);
            width: 100%;
            max-width: 489px;
            max-height: 717px;
            margin-right: 87px;
            @include respond-below(elg) {
              max-width: 420px;
            }
            @include respond-below(mlg) {
              max-width: 400px;
              max-height: 597px;
            }
            @include respond-below(lg) {
              max-width: 339px;
              max-height: 497px;
              margin-right: 0;
            }
            @include respond-below(sm) {
              max-width: 228px;
              max-height: 335px;
            }
          }
        }

        .collection-content {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-bottom: 95px;
          @include respond-below(lg) {
            padding-bottom: 53px;
          }
          @include respond-below(sm) {
            width: 100%;
            background-color: #C01F19;
            padding-bottom: 0;
            padding-top: 154px;
          }

          .collection-sub-desc {
            height: 90px;
            display: flex;
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 151%;
            color: #000000;
            margin-bottom: 44px;
            margin-left: 58px;
            @include respond-below(mlg) {
              margin-left: 20px;
            }
            @include respond-below(lg) {
              //flex-direction: column-reverse;
              //max-width: 260px;
              margin-bottom: 39px;
              margin-left: 53px;
              font-size: 16px;
            }
            @include respond-below(md) {
              margin-left: 30px;
            }
            @include respond-below(sm) {
              max-width: unset;
              color: #FCFAF5;
              margin-left: 0;
              padding-left: 35px;
              height: 48px;
            }
            @include respond-below(xs) {
              justify-content: flex-start;
              padding-left: 23px;
              padding-right: 23px;
              height: 78px;
              margin-bottom: 0;
            }

            p {
              max-width: 368px;
              @include respond-below(lg) {
                max-width: 200px;
              }
              @include respond-below(sm) {
                max-width: unset;
                color: #FCFAF5;
              }
            }

            a {
            }
          }

          .collection-desc {
            padding: 42px 0 51px 79px;
            @include respond-below(lg) {
              padding: 26px 0 38px 51px;
            }
            @include respond-below(mdw) {
              height: 272px;
            }
            @include respond-below(md) {
              padding: 26px 0 38px 30px;
            }
            @include respond-below(sm) {
              padding: 0;
              height: unset;
            }

            .collection-roasting {
              @include respond-below(sm) {
                display: flex;
                border-top: 1px solid #fff;
                border-bottom: 1px solid #fff;
                align-items: center;
                height: 73px;
                padding-left: 35px;
              }
              @include respond-below(xs) {
                padding-left: 23px;
              }

              .roasting-title {
                font-family: 'OpiumNewC';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 163%;
                text-transform: uppercase;
                color: #FBF9F4;
                margin-bottom: 10px;
                @include respond-below(lg) {
                  font-size: 14px;
                }
                @include respond-below(sm) {
                  margin: 0;
                  margin-right: 20px;
                }
              }

              .roasting-mark {
                display: flex;
                margin-bottom: 57px;
                @include respond-below(lg) {
                  margin-bottom: 47px;
                }
                @include respond-below(sm) {
                  margin: 0;
                }

                svg {
                  margin-right: 18px;
                  @include respond-below(lg) {
                    margin-right: 14px;
                  }
                }
              }
            }

            .roasting-taste-grid {
              display: grid;
              grid-template-columns: auto 10px auto;
              grid-template-rows: 45px 1px 45px;
              min-width: 400px;
              width: max-content;
              @include respond-below(mdw) {
                min-width: unset;
                max-width: 338px;
                width: 100%;
                grid-template-rows: auto 1px 45px;
              }
              @include respond-below(sm) {
                grid-template-rows: 71px 1px 71px;
                max-width: unset;
              }
              @include respond-below(xs) {
                grid-template-rows: 71px 1px 71px;
                max-width: unset;
              }

              .grid-title {
                font-family: 'OpiumNewC';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 100%;
                text-transform: uppercase;
                color: #FBF9F4;
                @include respond-below(lg) {
                  font-size: 14px;
                }
                @include respond-below(sm) {
                  padding-left: 35px;
                  padding-right: 21px;
                  display: flex;
                  align-items: center;
                }
                @include respond-below(xs) {
                  padding-left: 23px;
                }
              }

              .grid-desc {
                font-family: 'OpiumNewC';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 17px;
                text-transform: lowercase;
                color: #FBF9F4;
                padding-left: 25px;
                @include respond-below(lg) {
                  font-size: 14px;
                }
                @include respond-below(sm) {
                  display: flex;
                  align-items: center;
                  padding: 0 0 0 30px;
                }
                @include respond-below(xs) {
                  font-size: 13px;
                  line-height: 17px;
                }
                @include respond-below(mxs) {
                  padding: 0 15px;
                  font-size: 10px;
                }
              }

              .grid-empty {
                position: relative;
              }

              .grid-empty:after {
                @include respond-below(sm) {
                  content: "";
                  height: 100%;
                  max-height: 67px;
                  width: 1px;
                  margin: 0 auto;
                  left: 0;
                  right: 0;
                  background-color: #fff;
                  display: block;
                }
              }

              .text-bottom {
                align-self: flex-end;
                @include respond-below(sm) {
                  align-self: unset;
                }
              }

              .text-bottom.grid-empty {
                margin-top: 4px;
              }

              .grid-row-delimiter {
                height: 1px;
                background-color: #fff;
              }

              .grid-row-delimiter-rhombus {
                height: 1px;
                width: 15px;
                margin-left: -2px;
                padding-right: 2px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                  margin-top: 1px;
                }
              }
            }
          }


        }
      }
    }

    .slide.swiper-slide-active {
      .collection-slide-title {
        .title-text {
          color: #B61D1F;
          @include respond-below(xs) {
            max-width: 69% !important;
          }
        }

        margin-left: 10%;
        @include respond-below(mdw) {
          margin-left: 6%;
        }
      }

      .collection-img {
        img {
          transition-delay: 0.5s;
          opacity: 1 !important;
          -webkit-transform: translateY(0) !important;
          transform: translateY(0) !important;
        }
      }
    }
  }

  .collection-slider-prev {
    position: absolute;
    bottom: 307px;
    left: 11%;
    z-index: 1;
    cursor: pointer;
    @include respond-below(mlg) {
      left: 8%;
      bottom: 420px;
    }
    @include respond-below(lg) {
      bottom: 350px;
    }
    //@include respond-below(mdw) {
    //  bottom: 370px;
    //}
    @include respond-below(sm) {
      bottom: 665px;
    }
    @include respond-below(mxs) {
      bottom: 620px;
    }
  }

  .collection-slider-next {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    bottom: 286px;
    right: 0;
    height: 64px;
    width: 9.1%;
    background-color: #fff;
    padding: 32px;
    display: flex;
    align-items: center;
    background: linear-gradient(117deg, transparent 15px, #fff 0) top left, linear-gradient(62.3deg, transparent 15px, #fff 0) bottom left;
    background-size: 100% 50%;
    background-repeat: no-repeat;
    @include respond-below(mlg) {
      bottom: 400px;
    }
    @include respond-below(lg) {
      bottom: 330px;
      width: 120px;
    }
    //@include respond-below(mdw) {
    //  bottom: 350px;
    //}
    @include respond-below(sm) {
      bottom: 665px;
      background-color: transparent;
      padding: unset;
      right: 8%;
      width: unset;
      height: unset;
    }

    path {
      transition: 1.5s;
    }
  }

  .collection-pagination {
    top: 0;
    display: flex;
    justify-content: space-around;
    position: relative;
    @include respond-below(mdw) {
      overflow-x: scroll;
      top: 40px;
    }
    @include respond-below(sm) {
      position: absolute;
      bottom: 134px;
      top: unset;
      justify-content: flex-start;
    }

    span {
      white-space: nowrap;
      transition: 0.3s;
      width: auto;
      height: auto;
      background: transparent !important;
      font-family: 'bw-vivant';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 101%;
      letter-spacing: 0.005em;
      color: #A09488;
      opacity: 0.5;
      margin-right: 38px !important;
      @include respond-below(mlg) {
        font-size: 16px;
      }
    }

    span:first-child {
      margin-left: 22px;
    }

    span:last-child {
      margin-right: 22px;
    }

    .swiper-pagination-bullet-active {
      color: #000000;
      opacity: 1;
    }
  }

  .collection-pagination::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .collection-pagination {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }


}

.collection-slider-titles {
  z-index: 2;
  margin-bottom: 28px;
  @include respond-below(mlg) {
    padding-bottom: 0;
    //margin-bottom: 0;
  }
  @include respond-below(sm) {
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: -40px;
  }
}

.modal-cont {
  position: relative;

  .buy-modal {
    padding: 71px 0 90px;
    display: none;
    justify-content: space-between;
    flex-direction: column;
    z-index: 99;
    position: absolute;
    top: 313px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 1511px;
    height: 381px;
    background: rgba(251, 251, 251, 0.7);
    backdrop-filter: blur(10px);

    @include respond-below(elg) {
      width: 90%;
      height: 300px;
      padding: 30px 50px;
    }
    @include respond-below(lg) {
      top: 230px;
      height: auto;
      padding: 31px 0 50px;
    }
    @include respond-below(sm) {
      padding: 26px 36px 41px;
      top: unset;
      bottom: -1010px;
    }
    @include respond-below(xs) {
      width: 73.3%;
      min-height: 466px;
    }

    .modal-title {
      text-align: center;
      font-family: 'OpiumNewC';
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 45px;
      letter-spacing: 0.245em;
      text-transform: capitalize;
      color: #A09488;
      margin-bottom: 20px;
      @include respond-below(lg) {
        font-size: 24px;
        margin-bottom: 0;
      }
      @include respond-below(sm) {
        margin-bottom: 16px;
      }
      @include respond-below(xs) {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .modal-close {
      position: absolute;
      top: 19px;
      right: 19px;
    }

    .logos {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      @include respond-below(xs) {
        flex-direction: column;
        align-items: center;
      }

      .logo {
        margin-bottom: 10px;
        @include respond-below(sm) {
          margin-bottom: 25px;
        }
        @include respond-below(xs) {
          width: 100%
        }

        img {
          width: 100%;
        }
      }

      .yandex-logo {
        @include respond-below(xs) {
          width: 192px;
        }
      }

      .ozon-logo {
        @include respond-below(xs) {
          width: 126px;
        }
      }

      .wildberries-logo {
        @include respond-below(xs) {
          width: 164px;
        }
      }

      .komus-logo {
        @include respond-below(xs) {
          width: 105px;
        }
      }

      .treid-logo {
        @include respond-below(xs) {
          width: 135px;
        }
      }
    }
  }

  .buy-modal-open {
    display: flex;
  }
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes backward-infinite {
  0% {
    -webkit-transform: translate3D(0, 0, 0);
    transform: translate3D(0, 0, 0);
  }
  to {
    -webkit-transform: translate3D(calc((-100% / 3) - 0.125em), 0, 0);
    transform: translate3D(calc((-100% / 3) - 0.125em), 0, 0);
  }
}

@keyframes backward-infinite {
  0% {
    -webkit-transform: translate3D(0, 0, 0);
    transform: translate3D(0, 0, 0);
  }
  to {
    -webkit-transform: translate3D(calc((-100% / 3) - 0.125em), 0, 0);
    transform: translate3D(calc((-100% / 3) - 0.125em), 0, 0);
  }
}

.marquee {
  //display: table;
  //white-space: nowrap;
  //-webkit-animation: backward-infinite 50s linear infinite;
  //animation: backward-infinite 50s linear infinite;
  //pointer-events: none;
  //will-change: transform;
  transform: translateX(100%);
  transition: 3s;
}

.marquee-container {
  z-index: -1;
  overflow: hidden;
  align-self: flex-start;
  left: 0;
}

.buy-btn {
  transition: 0.6s;
  width: min-content;
  height: min-content;
  display: block;
  padding: 12px 66px;
  font-family: 'OpiumNewC';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.235em;
  color: #C01F19;
  background: #FCFAF6;
  border: 2px solid #C01F19;
  margin-left: 35px;
  @include respond-below(lg) {
    padding: 9px 43px;
    font-size: 12px;
    line-height: 18px;
  }
  @include respond-below(sm) {
    display: none;
  }
}

.buy-btn:hover {
  color: #fff;
  background: #C01F19;
}

.mob-buy-btm {
  display: none;
  @include respond-below(sm) {
    display: block;
    margin: 0 auto;
    margin-top: 111px;
  }
}

.red-slide {
  .red-desk {
    @include respond-below(sm) {
      background-color: #C01F19;
    }

    .collection-desc {
      background-color: #C01F19;
    }

    .grid-row-delimiter-rhombus {
      svg {
        path {
          fill: #C01F19;
        }
      }
    }
  }
}

.pink-slide {

  .buy-btn {
    color: #c87081;
    border: 2px solid #c87081;
  }

  .buy-btn:hover {
    color: #fff;
    background: #c87081;
  }

  .pink-desk {
    @include respond-below(sm) {
      background-color: #c87081 !important;
    }

    .collection-desc {
      background-color: #c87081;
    }

    .grid-row-delimiter-rhombus {
      svg {
        path {
          fill: #c87081;
        }
      }
    }
  }
}

.pink-slide.swiper-slide-active {
  .title-text {
    color: #c87081 !important;
  }

  .circle-text {
    .text {
      div {
        color: #c87081 !important;
      }
    }
  }
}

.brow-slide {

  .buy-btn {
    color: #9c4a41;
    border: 2px solid #9c4a41;
  }

  .buy-btn:hover {
    color: #fff;
    background: #9c4a41;
  }

  .brow-desk {
    @include respond-below(sm) {
      background-color: #9c4a41 !important;
    }

    .collection-desc {
      background-color: #9c4a41;
    }

    .grid-row-delimiter-rhombus {
      svg {
        path {
          fill: #9c4a41;
        }
      }
    }
  }
}

.brow-slide.swiper-slide-active {
  .title-text {
    color: #9c4a41 !important;
  }

  .circle-text {
    .text {
      div {
        color: #9c4a41 !important;
      }
    }
  }
}

.yellow-slide {
  .buy-btn {
    color: #d79853;
    border: 2px solid #d79853;
  }

  .buy-btn:hover {
    color: #fff;
    background: #d79853;
  }

  .yellow-desk {
    @include respond-below(sm) {
      background-color: #d79853 !important;
    }

    .collection-desc {
      background-color: #d79853;
    }

    .grid-row-delimiter-rhombus {
      svg {
        path {
          fill: #d79853;
        }
      }
    }
  }
}

.yellow-slide.swiper-slide-active {
  .title-text {
    color: #d79853 !important;
  }

  .circle-text {
    .text {
      div {
        color: #d79853 !important;
      }
    }
  }
}

.pink-swiper-arrow {
  path {
    fill: #c87081;
  }
}

.brow-swiper-arrow {
  path {
    fill: #9c4a41;
  }
}

.yellow-swiper-arrow {
  path {
    fill: #d79853;;
  }
}